.main-slider {
  background: #128197;
  height: 538px;
}
.banner-label {
  font-weight: 800;
  font-size: 45px;
  line-height: 140%;
  color: #ffffff;
}

.banner-des {
  font-family: 'Comfortaa', cursive;
  font-weight: bold;
  font-size: 24px;
  line-height: 156%;
  color: #ffffff;
  margin-top: 45px;
}
.app-download {
  margin-top: 50px;
}
.app-download label {
  font-family: 'Comfortaa', cursive;
  display: block;
  font-weight: 500;
  font-size: 17px;
  line-height: 140%;
  color: #ffffff;
}
.banner-img p {
  font-family: 'Comfortaa', cursive;
  color: #fff;
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: 700;
  font-size: 18px;
}
.app-download a img {
  width: 170px;
  padding-right: 10px;
}
.banner-img {
  text-align: center;
}
.mockup-img {
  /* text-align: right;
  padding-right: 50px; */
}
.mockup-img img {
  width: 250px;
}
.carousel-indicators {
  bottom: 0;
  /* z-index: 1; */
}
.carousel {
  height: 538px;
}
#canvas {
  filter: blur(0px);
}
.particals{
  position: absolute;
  width: 100%;
  height: 100%;
}
.padding{
  padding-left: 50px;
}