.policy-page-title {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #fff;
}
.policy-page-des {
    font-size: 18px;
    font-weight: 400;
    text-align: right;
    color: #fff;
}
.policy-banner{
    padding: 100px 100px 0px;
    background: #128197;
    position: relative;
}
.policy-sec{
    padding: 100px;
    background: #128197;
    position: relative;
    color: #fff;
}
.policy-sec strong{
    margin-right: 5px;
}
.policy-sec h2{
    border-bottom: 1px solid #fff;
    display: inline-block;
    margin-bottom: 30px;
}