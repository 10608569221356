.row {
  margin: 0;
  margin-right: 10px;
  margin-left: 32px;
  padding: 0;
}
.top-header {
  background: #08768b;
  padding: 6px 0;
}
.mail a {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
}
.mail a i {
  margin-right: 7px;
  font-size: 12px;
}
.social-links {
  text-align: right;
}
.social-links a {
  color: #fff;
  margin-left: 15px;
}
.social-links a i {
  font-size: 18px;
}

/* bottom header css */
.main-header {
  background: #128197;
}
.navbar-brand img {
  width: 90px;
}
li.nav-item {
  margin-right: 20px;
  padding: 0 10px;
}
li.nav-item .nav-link {
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #ffffff !important;
}
li.nav-item.active .nav-link {
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #ffffff !important;
}
ul.navbar-nav {
  margin-left: 50px;
}
.form-inline a {
  background: #65b4ce;
  border: 1px solid #65b4ce;
  border-radius: 100px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 138%;
  text-transform: uppercase;
  color: #ffffff;
  padding: 9px 28px;
}
.navbar-toggler .fa-bars {
  color: #fff;
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: #fff;
  border: none;
}
