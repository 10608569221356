.about-sec {
    background: #08768B;
    padding: 50px;
    text-align: center;
    position: relative;
}
.about-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #FFFFFF;
    margin-bottom: 20px;
}
.about-des {
    font-family: 'Comfortaa', cursive;
    font-weight: 400;
    font-size: 19px;
    line-height: 156%;
    text-align: center;
    color: #FFFFFF;
    padding: 0 70px;
}
.left-bubble{
    position: absolute;
    left: 20px;
    top: -60px;
}
.right-bubble{
    position: absolute;
    right: 20px;
    top: 50px;
}